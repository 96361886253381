import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Terms of Service (October 24, 2017)',
  header: 'Terms of Service (October 24, 2017)',
  description: 'Uploadcare Terms of Service (October 24, 2017): please read this Agreement carefully before accessing or using the Website.',
  canonical: 'https://uploadcare.com/about/terms/',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Note: this version of Terms of Service is outdated, the relevant document came
into effect on May 25, 2018 and can be found `}<a parentName="p" {...{
        "href": "/about/terms/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Effective Date: October 24, 2017`}</p>
    <p>{`Uploadcare LLC (“Uploadcare”, “our”, “us” or “we”) provides the Uploadcare
website and application.`}</p>
    <p>{`The following terms and conditions govern all use of the Uploadcare.com website
d all content, services and products available at or through the website,
including, but not limited to, the Uploadcare widgets, plug-ins, modules and
hosting services (“Uploadcare Services”), (taken together, the Website). The
Website is owned and operated by Uploadcare LLC. (“Uploadcare”).`}</p>
    <p>{`The Website is offered subject to your acceptance without modification of all of
the terms and conditions contained herein and all other operating rules,
policies (including, without limitation, Uploadcare’s Privacy Policy) and
procedures that may be published from time to time on this Site by Uploadcare
(collectively, the “Agreement”).`}</p>
    <p>{`Please read this Agreement carefully before accessing or using the Website. By
accessing or using any part of the web site, you agree to become bound by the
terms and conditions of this agreement. If you do not agree to all the terms and
conditions of this agreement, then you may not access the Website or use any
services. If these terms and conditions are considered an offer by Uploadcare,
acceptance is expressly limited to these terms. The Website is available only to
individuals who are at least 13 years old.`}</p>
    <a id="terms-site-account" href="#terms-site-account"></a>
    <ol>
      <li parentName="ol">{`Your Uploadcare Account and Site. If you create an account on the Website,
you are responsible for maintaining the security of your account and its
content, and you are fully responsible for all activities that occur under
the account and any other actions taken in connection with the Website. You
must not describe or assign content to your account in a misleading or
unlawful manner, including in a manner intended to trade on the name or
reputation of others, and Uploadcare may change or remove any content or
photos that it considers inappropriate or unlawful, or otherwise likely to
cause Uploadcare liability. You must immediately notify Uploadcare of any
unauthorized uses of your account any other breaches of security. Uploadcare
will not be liable for any acts or omissions by You, including any damages of
any kind incurred as a result of such acts or omissions.`}</li>
    </ol>
    <a id="terms-responsibility" href="#terms-responsibility"></a>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Responsibility of Contributors. If you operate an account, post material to
the Website, post photos to the Website, or otherwise make (or allow any
third party to make) material available by means of the Website (any such
material, “Content”), You are entirely responsible for the content of, and
any harm resulting from, that Content. That is the case regardless of
whether the Content in question constitutes text, graphics, an audio file,
or computer software. By making Content available, you represent and warrant
that:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`the downloading, copying and use of the Content will not infringe the
proprietary rights, including but not limited to the copyright, patent,
trademark or trade secret rights, of any third party;`}</li>
          <li parentName="ul">{`if your employer has rights to intellectual property you create, you have
ther (i) received permission from your employer to post or make available
the Content, including but not limited to any software, or (ii) secured
from your employer a waiver as to all rights in or to the Content;`}</li>
          <li parentName="ul">{`you have fully complied with any third-party licenses relating to the
Content, and have done all things necessary to successfully pass through
to end users any required terms; the Content does not contain or install
any viruses, worms, malware, Trojan horses or other harmful or destructive
content;`}</li>
          <li parentName="ul">{`the Content is not spam, is not machine- or randomly-generated, and does
not contain unethical or unwanted commercial content designed to drive
traffic to third party sites or boost the search engine rankings of third
party sites, or to further unlawful acts (such as phishing) or mislead
recipients as to the source of the material (such as spoofing);`}</li>
          <li parentName="ul">{`the Content is not pornographic, does not contain threats or incite
violence towards individuals or entities, and does not violate the privacy
or publicity rights of any third party;`}</li>
          <li parentName="ul">{`your account is not getting advertised via unwanted electronic messages
such as spam links on newsgroups, email lists, other blogs and web sites,
and similar unsolicited promotional methods;`}</li>
          <li parentName="ul">{`your account is not named in a manner that misleads your readers or users
into thinking that you are another person or company. For example, your
website’s URL or name is not the name of a person other than yourself or
company other than your own; and`}</li>
          <li parentName="ul">{`you have, in the case of Content that includes computer code, accurately
categorized and/or described the type, nature, uses and effects of the
materials, whether requested to do so by Uploadcare or otherwise.`}</li>
        </ul>
      </li>
    </ol>
    <a id="terms-content" href="#terms-content"></a>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`By submitting Content to Uploadcare for inclusion on your Website, you grant
Uploadcare a world-wide, royalty- free, and non-exclusive license to
reproduce, modify, adapt and publish the Content solely for the purpose of
displaying, distributing and promoting your account. If you delete Content,
Uploadcare will use reasonable efforts to remove it from the Website, but you
acknowledge that caching or references to the Content may not be made
immediately unavailable.`}</li>
    </ol>
    <a id="terms-content-remove" href="#terms-content-remove"></a>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Without limiting any of those representations or warranties, Uploadcare has
the right (though not the obligation) to, in Uploadcare’s sole discretion (i)
refuse or remove any content that, in Uploadcare’s reasonable opinion,
violates any Uploadcare policy or is in any way harmful or objectionable, or
(ii) terminate or deny access to and use of the Website to any individual or
entity for any reason, in Uploadcare’s sole discretion. Uploadcare will have
no obligation to provide a refund of any amounts previously paid.`}</li>
    </ol>
    <a id="terms-payment" href="#terms-payment"></a>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`Payment and Renewal.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`General Terms`}</strong>{` Optional paid services such as paid accounts, extra
storage, and premium support are available on the Website (any such
services, an “Upgrade”). By selecting an Upgrade you agree to pay
Uploadcare the monthly or annual subscription fees indicated for that
service. Payments will be charged on a pre-pay basis on the day you sign up
for an Upgrade and will cover the use of that service for a monthly or
annual subscription period as indicated. Upgrade fees are not refundable.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Automatic Renewal`}</strong>{` Unless you notify Uploadcare before the end of the
applicable subscription period that you want to cancel an Upgrade, your
Upgrade subscription will automatically renew and you authorize us to
collect the then-applicable annual or monthly subscription fee for such
Upgrade (as well as any taxes) using any credit card or other payment
mechanism we have on record for you. Upgrades can be canceled at any time
in the Upgrades section of your site’s dashboard.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <a id="terms-paid-services" href="#terms-paid-services"></a>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">
        <p parentName="li">{`Paid Services.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Fees; Payment`}</strong>{` By signing up for a Paid Services account you agree to
pay Uploadcare the monthly fees indicated at
`}<a parentName="p" {...{
                "href": "http://uploadcare.com/pricing/"
              }}>{`http://uploadcare.com/pricing/`}</a>{`. Applicable fees will be invoiced starting
from the day your Paid Services are established and in advance of using
such services. Uploadcare reserves the right to change the payment terms
and fees upon thirty (30) days prior written notice to you. Paid Services
can be canceled by you at anytime through your Account Settings page.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Support`}</strong>{` Certain Paid Services packages include access to priority email
and telephone support. “Email support” means the ability to make requests
for technical support assistance by email at any time (with reasonable
efforts by Uploadcare to respond within one business day) concerning the
use of the Paid Services. “Priority” means that support for Paid Services
customers takes priority over support for users of the standard, free
Uploadcare services. All Paid Services support will be provided in
accordance with Uploadcare standard Paid Services practices, procedures and
policies.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <a id="terms-responsibility-visitors" href="#terms-responsibility-visitors"></a>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol"><strong parentName="li">{`Responsibility of Website Visitors`}</strong>{` Uploadcare has not reviewed, and
cannot review, all of the material, including computer software, posted to
the Website, and cannot therefore be responsible for that material’s content,
use or effects. By operating the Website, Uploadcare does not represent or
imply that it endorses the material there posted, or that it believes such
material to be accurate, useful or non- harmful. You are responsible for
taking precautions as necessary to protect ourself and your computer systems
from viruses, worms, Trojan horses, and other harmful or destructive content.
The Website may contain content that is offensive, indecent, or otherwise
objectionable, as well as content containing technical inaccuracies,
typographical mistakes, and other errors. The Website may also contain
material that violates the privacy or publicity rights, or infringes the
intellectual property and other proprietary rights, of third parties, or the
downloading, copying or use of which is subject to additional terms and
conditions, stated or unstated. Uploadcare disclaims any responsibility for
any harm resulting from the use by visitors of the Website, or from any
downloading by those visitors of content there posted.`}</li>
    </ol>
    <a id="terms-non-uc-content" href="#terms-non-uc-content"></a>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol"><strong parentName="li">{`Content Posted on Other Websites`}</strong>{` We have not reviewed, and cannot review,
all of the material, including computer software, made available through the
websites and webpages to which we link, and that link to us. Uploadcare does
not have any control over those non-Uploadcare websites and webpages, and is
not responsible for their contents or their use. By linking to a
non-Uploadcare website or webpage, Uploadcare does not represent or imply
that it endorses such website or webpage. You are responsible for taking
precautions as necessary to protect yourself and your computer systems from
viruses, worms, Trojan horses, and other harmful or destructive content.
Uploadcare disclaims any responsibility for any harm resulting from your use
of non-Uploadcare websites and webpages.`}</li>
    </ol>
    <a id="terms-copyright-dmca" href="#terms-copyright-dmca"></a>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol"><strong parentName="li">{`Copyright Infringement and DMCA Policy`}</strong>{` As Uploadcare asks others to
respect its intellectual property rights, it respects the intellectual
property rights of others. If you believe that material located on or linked
to by Uploadcare violates your copyright, you are encouraged to notify
Uploadcare in accordance with Uploadcare’s Digital Millennium Copyright Act
(“DMCA”) Policy. Uploadcare will respond to all such notices, including as
required or appropriate by removing the infringing material or disabling all
links to the infringing material. Uploadcare will terminate a visitor’s
access to and use of the Website if, under appropriate circumstances, the
visitor is determined to be a repeat infringer of the copyrights or other
intellectual property rights of Uploadcare or others. In the case of such
termination, Uploadcare will have no obligation to provide a refund of any
amounts previously paid to Uploadcare.`}</li>
    </ol>
    <a id="terms-intellectual-property" href="#terms-intellectual-property"></a>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol"><strong parentName="li">{`Intellectual Property`}</strong>{` This Agreement does not transfer from Uploadcare
to you any Uploadcare or third party intellectual property, and all right,
title and interest in and to such property will remain (as between the
parties) solely with Uploadcare. Uploadcare, Uploadcare LLC, Uploadcare.com,
the Uploadcare logo, and all other trademarks, service marks, graphics and
logos used in connection with Uploadcare, or the Website are trademarks or
registered trademarks of Uploadcare or Uploadcare’s licensors. Other
trademarks, service marks, graphics and logos used in connection with the
Website may be the trademarks of other third parties. Your use of the
Website grants you no right or license to reproduce or otherwise use any
Uploadcare or third-party trademarks.`}</li>
    </ol>
    <a id="terms-ads" href="#terms-ads"></a>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol"><strong parentName="li">{`Advertisements`}</strong>{` Uploadcare reserves the right to display advertisements
in your Uploadcare display widgets unless you have purchased an ad-free Paid
Services account.`}</li>
    </ol>
    <a id="terms-mailing-list" href="#terms-mailing-list"></a>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol"><strong parentName="li">{`Mailing list`}</strong>{` As signing up on Uploadcare you are automatically
subscribing to our mailing list. You can unsubscribe from it at any time.`}</li>
    </ol>
    <a id="terms-changes" href="#terms-changes"></a>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol"><strong parentName="li">{`Changes`}</strong>{` Uploadcare reserves the right, at its sole discretion, to modify
or replace any part of this Agreement. It is your responsibility to check
this Agreement periodically for changes. Your continued use of or access to
the Website following the posting of any changes to this Agreement
constitutes acceptance of those changes. Uploadcare may also, in the future,
offer new services and/or features through the Website (including, the
release of new tools and resources). Such new features and/or services shall
be subject to the terms and conditions of this Agreement.`}</li>
    </ol>
    <a id="terms-termination" href="#terms-termination"></a>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol"><strong parentName="li">{`Termination`}</strong>{` Uploadcare may terminate your access to all or any part of
the Website at any time, with or without cause, with or without notice,
effective immediately. If you wish to terminate this Agreement or your
Uploadcare account (if you have one), you may simply discontinue using the
Website. Not withstanding the foregoing, if you have a Paid Services
account, such account can only be terminated by Uploadcare if you materially
breach this Agreement and fail to cure such breach within thirty (30) days
from Uploadcare’s notice to you thereof; provided that, Uploadcare can
terminate the Website immediately as part of a general shut down of our
service. All provisions of this Agreement which by their nature should
survive termination shall survive termination, including, without
limitation, ownership provisions, warranty disclaimers, indemnity and
limitations of liability.`}</li>
    </ol>
    <a id="terms-no-warranties" href="#terms-no-warranties"></a>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol"><strong parentName="li">{`Disclaimer of Warranties`}</strong>{` The Website is provided “as is”. Uploadcare and
its suppliers and licensors hereby disclaim all warranties of any kind,
express or implied, including, without limitation, the warranties of
merchantability, fitness for a particular purpose and non-infringement.
Neither Uploadcare nor its suppliers and licensors, makes any warranty that
the Website will be error free or that access thereto will be continuous or
uninterrupted. You understand that you download from, or otherwise obtain
content or services through, the Website at your own discretion and risk.`}</li>
    </ol>
    <a id="terms-liability" href="#terms-liability"></a>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol"><strong parentName="li">{`Limitation of Liability`}</strong>{` In no event will Uploadcare, or its suppliers or
licensors, be liable with respect to any subject matter of this agreement
under any contract, negligence, strict liability or other legal or equitable
theory for: (i) any special, incidental or consequential damages; (ii) the
cost of procurement for substitute products or services; (iii) for
interruption of use or loss or corruption of data; or (iv) for any amounts
that exceed the fees paid by you to Uploadcare under this agreement during
the twelve (12) month period prior to the cause of action. Uploadcare shall
have no liability for any failure or delay due to matters beyond their
reasonable control. The foregoing shall not apply to the extent prohibited
by applicable law.`}</li>
    </ol>
    <a id="terms-representation-warranty" href="#terms-representation-warranty"></a>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol"><strong parentName="li">{`General Representation and Warranty`}</strong>{` You represent and warrant that (i)
your use of the Website will be in strict accordance with the Uploadcare
Privacy Policy, with this Agreement and with all applicable laws and
regulations (including without limitation any local laws or regulations in
your country, state, city, or other governmental area, regarding online
conduct and acceptable content, and including all applicable laws regarding
the transmission of technical data exported from the United States or the
country in which you reside) and (ii) your use of the Website will not
infringe or misappropriate the intellectual property rights of any third
party.`}</li>
    </ol>
    <a id="terms-indemnification" href="#terms-indemnification"></a>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol"><strong parentName="li">{`Indemnification`}</strong>{` You agree to indemnify and hold harmless Uploadcare, its
contractors, and its licensors, and their respective directors, officers,
employees and agents from and against any and all claims and expenses,
including attorneys’ fees, arising out of your use of the Website, including
but not limited to your violation of this Agreement.`}</li>
    </ol>
    <a id="terms-misc" href="#terms-misc"></a>
    <ol {...{
      "start": 19
    }}>
      <li parentName="ol"><strong parentName="li">{`Miscellaneous`}</strong>{` This Agreement constitutes the entire agreement between
Uploadcare and you concerning the subject matter hereof, and they may only
be modified by a written amendment signed by an authorized executive of
Uploadcare, or by the posting by Uploadcare of a revised version. Except to
the extent applicable law, if any, provides otherwise, this Agreement, any
access to or use of the Website will be governed by the laws of the state of
California, U.S.A., excluding its conflict of law provisions, and the proper
venue for any disputes arising out of or relating to any of the same will be
the state and federal courts located in Los Angeles, California. Except for
claims for injunctive or equitable relief or claims regarding intellectual
property rights (which may be brought in any competent court without the
posting of a bond), any dispute arising under this Agreement shall be
finally settled in accordance with the Comprehensive Arbitration Rules of
the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by three
arbitrators appointed in accordance with such Rules. The arbitration shall
take place in Los Angeles, California, in the English language and the
arbitral decision may be enforced in any court. The prevailing party in any
action or proceeding to enforce this Agreement shall be entitled to costs
and attorneys’ fees. If any part of this Agreement is held invalid or
unenforceable, that part will be construed to reflect the parties’ original
intent, and the remaining portions will remain in full force and effect. A
waiver by either party of any term or condition of this Agreement or any
breach thereof, in any one instance, will not waive such term or condition
or any subsequent breach thereof. You may assign your rights under this
Agreement to any party that consents to, and agrees to be bound by, its
terms and conditions; Uploadcare may assign its rights under this Agreement
without condition. This Agreement will be binding upon and will inure to the
benefit of the parties, their successors and permitted assigns.`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/2.5/"
      }}><img parentName="a" {...{
          "src": "https://licensebuttons.net/l/by-sa/2.5/88x31.png",
          "alt": "Creative Commons Attribution-ShareAlike 2.5 Generic License"
        }}></img></a>{`
This text is licensed under `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/2.5/"
      }}>{`Creative Commons Attribution-ShareAlike 2.5 Generic
License`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      